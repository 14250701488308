// REPLACE FROM HERE
var config = {
    apiKey: "AIzaSyA-eGsD_Jvu_H3BvdKBUrKK0nDfUQ_9ThM",
    authDomain: "swrpg-web-dice-roller.firebaseapp.com",
    databaseURL: "https://swrpg-web-dice-roller.firebaseio.com",
    projectId: "swrpg-web-dice-roller",
    storageBucket: "swrpg-web-dice-roller.appspot.com",
    messagingSenderId: "710195066173"
};
// END REPLACE

exports.config = config;